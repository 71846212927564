<template>
  <div class="container-fluid p-0">
    <div class="auth-bg-video-1 ">
      <video id="bgvid" poster="@/assets/images/pages/login/across-benlea-to-mount.jpg" playsInline="" autoPlay=""
             muted=""
             loop=""
      >
        <source src="@/assets/images/pages/login/auth-bg-2.mp4" type="video/mp4">
      </video>
      <div class="glassmorphism">
        <HomeHeader></HomeHeader>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHeader from '@/views/home-page/components/HomeHeader.vue'

export default {
  name: 'MySpace',
  components: {
    HomeHeader
  },
  props: {
    msg: String,
  },
  methods: {
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  mounted: function() {
    if (this._isMobile()) {
      this.$router.replace('/phone/myspace');
    }else {
      this.$router.replace('/myspace');
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import '@core/scss/vue/pages/page-auth.scss';

</style>